// Header.js
import React from "react";
import { NavLink } from "react-router-dom";
import "./Header.css"; // Custom CSS for additional styling
import VrioLogo from "../Assets/VrioLogo.png";

const Header = () => {
  return (
    <div>
      <header className="header">
        <div className="container">
          <div className="header-content">
            <div className="logo-container">
              <NavLink to="/" className="navbar-brand">
                <img src={VrioLogo} alt="Logo" className="logo" />
              </NavLink>
            </div>

            <div className="nav-container">
              <NavLink to="/home" className="nav-link" activeClassName="active">
                HOME
              </NavLink>
              <NavLink
                to="/about"
                className="nav-link"
                activeClassName="active"
              >
                ABOUT US
              </NavLink>
              <NavLink
                to="/industries"
                className="nav-link"
                activeClassName="active"
              >
                INDUSTRIES
              </NavLink>
              <NavLink
                to="/solutions"
                className="nav-link"
                activeClassName="active"
              >
                SOLUTIONS
              </NavLink>
              <NavLink to="/team" className="nav-link" activeClassName="active">
                OUR TEAM
              </NavLink>
              <NavLink to="/join" className="nav-link" activeClassName="active">
                JOIN US
              </NavLink>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
